import React from 'react'
import styled from '@emotion/styled'
import { theme } from '../../styles'
import Text from '../common/Text'
import Button from '../common/Button'

const BG = styled.div`
  position: absolute;
  width: 500vw;
  margin-left: -300%;
  height: 100%;
  z-index: -1;
  background-color: ${theme.color.purple900};
  top: 0;

  @media (max-width: 1050px) {
    margin-left: -100%;
  }
`

const BannerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% + 300px);
  margin-left: -150px;
  padding: 80px 150px;
  gap: 64px;

  @media (max-width: 1050px) {
    width: 100%;
    margin-left: 0px;
    flex-direction: column;
    gap: 32px;
    height: 100%;
    padding: 56px 0px;
  }
`

const TextWrapper = styled.div`
  @media (max-width: 1050px) {
    text-align: center;
  }
`

const BlogCta = () => (
  <BannerWrapper>
    <TextWrapper>
      <Text heading3 aria-level="2" color="white">
        Learn How to Make Your Website More Accessible
      </Text>
    </TextWrapper>
    <Button text="Download Guide" to="/accessible-web-design/" buttonType="tertiary reversed" />
    <BG className="bg" />
  </BannerWrapper>
)

export default BlogCta

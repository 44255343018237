import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'

import styled from '@emotion/styled'
import idx from 'idx'
import formatDate from '../../utils/formatDate'
import Text from '../common/Text'
import Space from '../common/Space'
import Button from '../common/Button'
import getImage from '../../utils/getImage'
import { theme } from '../../styles'
import Tag from '../common/Tag'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  position: relative;
  border-radius: 8px;
  background: ${theme.color.white};
  border-width: 0px;
  cursor: pointer;
  transition: all 125ms ease-in-out;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);

  &:hover {
    transform: scale(1.01);
  }

  @media (max-width: 1050px) {
    flex-direction: column;
    grid-template-columns: 1fr;
  }
`

const BG = styled.div`
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, ${theme.color.green500}, ${theme.color.purple500}, ${theme.color.purple900});
  z-index: -1;
  filter: blur(24px);
  opacity: 0.4;
  transition: all 125ms ease-in-out;
`

const ImgWrapper = styled.div`
  grid-column: 2 / 3;
  width: 100%;

  @media (max-width: 1050px) {
    grid-row: 1 / 2;
    grid-column: 1 / -1;
  }
`

const Img = styled.img`
  border-radius: 0px 8px 8px 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1050px) {
    border-radius: 8px 8px 0px 0px;
  }
`

const CardContent = styled.div`
  display: grid;
  gap: 8px;
  grid-column: 1 / 2;
  width: 100%;
  background-color: white;
  padding: 40px;
  border-radius: 8px 0px 0px 8px;

  @media (max-width: 1350px) {
    padding: 24px;
  }

  @media (max-width: 1050px) {
    border-radius: 0px 0px 8px 8px;
    padding: 32px;
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column-reverse;
  }

  @media (max-width: 800px) {
    padding: 24px 16px;
  }
`

const CardCopy = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  @media (max-width: 1050px) {
    margin-bottom: 0px;
    margin-top: 16px;
  }
`

const BottomContent = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1050px) {
    display: none;
  }
`

const FeaturedPost = () => {
  const featuredPostData = useStaticQuery(graphql`
    query postQuery {
      allPrismicPost(
        limit: 3
        filter: { data: { post_type: { eq: "Blog" }, publish_date: { ne: null } }, lang: { eq: "en-us" } }
        sort: { fields: data___publish_date, order: DESC }
      ) {
        edges {
          node {
            id
            uid
            data {
              title {
                text
              }
              hide_from_feeds
              password
              banner {
                alt
                url
                gatsbyImageData(width: 655)
              }
              description
              publish_date
              categories {
                category {
                  document {
                    ... on PrismicCategory {
                      id
                      data {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const publicPosts = featuredPostData.allPrismicPost.edges.filter(post => {
    // Only allow non hidden and non PW protected posts to be featured
    if (!post.node.data.hide_from_feeds && !post.node.data.password) {
      return post
    }
    return null
  })
  const featPost = publicPosts[0].node

  const defaultImageUrl =
    'https://images.prismic.io/audioeye-web/953cc937-895c-47a9-ab87-0e60c31f5c1e_audioeye-default-blog-image.png?auto=compress,format&w=655'
  const image = featPost.data.banner || { url: defaultImageUrl }

  return (
    <Wrapper
      className="link-list-item"
      onClick={() => navigate(`/post/${idx(featPost, _ => _.uid)}`)}
      onKeyDown={e => {
        if (e.key === 'Enter') navigate(`/post/${idx(featPost, _ => _.uid)}`)
      }}
    >
      <CardContent>
        <CardHeader>
          {featPost.data.categories?.[0]?.category?.document?.data?.name ? (
            <Tag
              text={featPost.data.categories[0].category.document.data.name}
              color="gray"
              fontSize="smallCaps"
              fontFamily="smallBody"
              noMargin
            />
          ) : (
            <Space width={16} />
          )}
          <Text smallCaps color="gray700">
            {featPost.data.publish_date && formatDate(featPost.data.publish_date)}
          </Text>
        </CardHeader>
        <CardCopy>
          <Text heading3 aria-level="2" numberOfLines={3}>
            {featPost.data.title.text}
          </Text>
          <Text body numberOfLines={2}>
            {featPost.data.description}
          </Text>
          <BottomContent>
            <Button
              buttonType="link CTA"
              text="Read this article"
              aria-label={`Read ${featPost.data.title.text} article`}
              to={`/post/${idx(featPost, _ => _.uid)}`}
              onClick={e => e.stopPropagation()}
            />
          </BottomContent>
        </CardCopy>
      </CardContent>
      <ImgWrapper>{getImage(image, Img)}</ImgWrapper>
      <BG />
    </Wrapper>
  )
}

export default FeaturedPost

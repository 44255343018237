import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'

import PostGridItem from './PostGridItem'

import Text from '../common/Text'

const Wrapper = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  padding: 80px 0px;

  @media (max-width: 1050px) {
    padding: 64px 0px;
  }
`

const BG = styled.div`
  position: absolute;
  width: 500vw;
  margin-left: -300%;
  height: 100%;
  z-index: -1;
  background-color: #fafafa;

  @media (max-width: 1050px) {
    margin-left: -100%;
  }
`

const PostsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;

  .react-reveal {
    flex-basis: ${p => (p.numPosts > 4 ? '30%' : '20%')};
    flex-grow: 1;
  }

  @media (max-width: 1050px) {
    display: grid;
    gap: 24px;
    justify-items: center;
    .react-reveal {
      max-width: 500px;
    }
  }
`

const HeaderWrapper = styled.div`
  display: grid;
  gap: 32px;
  padding-bottom: 32px;

  @media (max-width: 1050px) {
    * {
      text-align: center !important;
    }
  }
`

const BlogHighlight = () => {
  const highlightQuery = graphql`
    query highlightQuery {
      prismicBlogHighlight {
        data {
          highlight_posts {
            post {
              document {
                ... on PrismicPost {
                  id
                  uid
                  data {
                    title {
                      text
                    }
                    publish_date
                    banner {
                      alt
                      url
                      gatsbyImageData(width: 540)
                    }
                    categories {
                      category {
                        document {
                          ... on PrismicCategory {
                            id
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          title {
            ...TextContent
          }
          text {
            ...TextContent
          }
        }
      }
    }
  `

  return (
    <StaticQuery
      query={highlightQuery}
      render={({ prismicBlogHighlight }) => {
        const { data } = prismicBlogHighlight
        const title = <PrismicRichText field={data.title.richText} />
        const text = <PrismicRichText field={data.text.richText} />
        const highlightPosts = data.highlight_posts

        return (
          <Wrapper>
            <HeaderWrapper>
              <Text font="heading2" richText>
                {title}
              </Text>
              <Text richText>{text}</Text>
            </HeaderWrapper>
            <PostsWrapper numPosts={highlightPosts.length}>
              {highlightPosts.map(
                ({ post }, i) =>
                  i < 6 &&
                  post?.document?.uid && (
                    <PostGridItem
                      key={post.document?.uid}
                      uid={post.document?.uid}
                      publishDate={post.document?.data.publish_date}
                      title={post.document?.data.title.text}
                      index={i}
                      img={post.document?.data.banner}
                      categories={post.document?.data?.categories}
                    />
                  )
              )}
            </PostsWrapper>
            <BG className="bg" />
          </Wrapper>
        )
      }}
    />
  )
}

export default BlogHighlight

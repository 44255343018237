/* eslint-disable camelcase */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import SEO from '../components/SEO'
import Text from '../components/common/Text'
import Space from '../components/common/Space'
import BlogCta from '../components/blog/BlogCta'
import BlogHighlight from '../components/blog/BlogHighlight'
import Layout from '../components/Layout'

import PostsGrid from '../components/blog/PostsGrid'
import FeaturedPost from '../components/postSlices/FeaturedPost'
import NewsletterSubscribe from '../components/form/NewsletterSubscribe'

const Wrapper = styled.div`
  position: relative;
  width: calc(100% + 150px);
  margin-left: -75px;
  margin-top: 56px;

  @media (max-width: 1050px) {
    margin-left: 0px;
    width: 100%;
  }
`

const Blog = () => {
  const recentPostsQuery = useStaticQuery(graphql`
    query recentPostsQuery {
      allPrismicPost(
        sort: { fields: data___publish_date, order: DESC }
        filter: {
          data: {
            post_type: { eq: "Blog" }
            publish_date: { ne: null }
            hide_from_feeds: { eq: false }
            password: { eq: null }
          }
        }
      ) {
        totalCount
        edges {
          node {
            uid
            data {
              description
              publish_date
              hide_from_feeds
              password
              title {
                text
              }
              banner {
                alt
                url
                gatsbyImageData(width: 540)
              }
              categories {
                category {
                  document {
                    ... on PrismicCategory {
                      id
                      data {
                        name
                      }
                    }
                  }
                }
              }
              tags {
                tag {
                  document {
                    ... on PrismicTag {
                      id
                      data {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout customSEO showHeader>
      <SEO
        title="Blog | AudioEye"
        pathname={typeof window !== 'undefined' ? window.location.pathname : null}
        desc="Check out the latest stories about digital accessibility, WCAG / ADA website compliance, AudioEye, and more."
      />
      <Wrapper>
        <Text heading1>AudioEye Blog</Text>
        <Space height={80} mobileHeight={48} />
        <FeaturedPost />
        <Space height={85} mobileHeight={53} />
        {recentPostsQuery?.allPrismicPost && (
          <PostsGrid showSearch pagination={false} allPostsData={recentPostsQuery?.allPrismicPost} />
        )}
        <BlogCta />
        <BlogHighlight />
        <Space height={100} mobileHeight={48} />
        <NewsletterSubscribe />
        <Space height={100} mobileHeight={48} />
      </Wrapper>
    </Layout>
  )
}

export default Blog
